import { AdaptiveModal, Link, NavigationPage } from '@/app/components'
import { useSurveyResponder } from '@/app/components/SurveyObserver'
import { useMarkSurveySeen } from '@/app/components/SurveyObserver/reducer'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'
import DeviceConnectionRedirect, {
  ConnectionStatusMode,
} from '@/authenticated/components/DeviceConnectionRedirect'
import { Button, CardAction, CardActions, TextField } from '@/components'
import { useMockableViewModel } from '@/hooks'
import { capitalizeFirstLetter } from '@/utils/stringUtilities'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { useUserWhitelabelSlug } from '@/app/hooks/whitelabelNav'

const brandType = ['charger', 'vehicle', 'thermostat'] as const
export type BrandType = (typeof brandType)[number]
const makeModelValidationSchema = yup.object({
  make: yup.string().required().label('Make'),
  model: yup.string().label('Model'),
  type: yup.mixed<BrandType>().oneOf([...brandType]),
})
type BrandNotFoundInput = {
  make: string
  model: string
  type?: BrandType
}
function useMakeModelInput({
  onSubmit,
  type,
}: {
  onSubmit(values: BrandNotFoundInput): void
  type?: BrandType
}) {
  const formik = useFormik({
    initialValues: {
      make: '',
      model: '',
      type,
    },
    validationSchema: makeModelValidationSchema,
    onSubmit: onSubmit,
  })

  return {
    inputValues: formik.values,
    inputErrors: {
      make: formik.touched.make ? formik.errors.make : undefined,
      model: formik.touched.model ? formik.errors.model : undefined,
      type: formik.touched.type ? formik.errors.type : undefined,
    },
    onValueChange: formik.handleChange,
    onSubmit: formik.handleSubmit,
    submitEnabled: formik.dirty && formik.isValid,
  }
}

export const useViewModel = useMockableViewModel({
  useViewModel() {
    const history = useHistory()
    const { appendResponse } = useSurveyResponder('MissingBrand')
    const [submitted, setSubmitted] = useState(false)
    const onSubmit = async (value: BrandNotFoundInput) => {
      setSubmitted(true)
      await appendResponse(value)
    }

    useMarkSurveySeen({
      // one hour
      withInTimeMs: 1000 * 60 * 60,
      surveyType: 'MissingBrand',
    })

    const onFinish = () => {
      history.goBack()
    }

    return {
      ...useMakeModelInput({ onSubmit }),
      onFinish,
      submitted,
    }
  },
  useMockViewModel() {
    const [submitted, setSubmitted] = useState(false)
    return {
      ...useMakeModelInput({
        onSubmit: () => setSubmitted(true),
      }),
      onFinish: () => {},
      submitted,
    }
  },
})

export function BrandNotFound({ type }: { type: BrandType }) {
  const {
    inputValues,
    inputErrors,
    onValueChange,
    onSubmit,
    submitEnabled,
    onFinish,
    submitted,
  } = useViewModel()
  inputValues.type = type
  const isAva = useIsAvaBasePath()
  const isOnboarding = useLocation().pathname.includes('/onboarding/')
  const redirect =
    type === 'charger'
      ? {
          link:
            isAva && isOnboarding
              ? '/ava/connect-device/vehicle?onboarding=true'
              : '/connect-device/vehicle',
          device: 'vehicle',
        }
      : {
          link:
            isAva && isOnboarding
              ? '/ava/onboarding/connect-device/charger/select'
              : '/connect-device/charger/select',
          device: 'charger',
        }

  return (
    <NavigationPage
      id={`${type}-brand-not-found-page`}
      title="I don’t see my brand"
      subtitle={`What brand of ${type} do you own?`}
    >
      <form
        noValidate
        autoComplete="off"
        style={{ height: '100%' }}
        onSubmit={onSubmit}
      >
        <NavigationPage.SingleCardContent>
          <TextField
            id="make"
            type="text"
            placeholder={`${capitalizeFirstLetter(type)} ${
              type === 'vehicle' ? 'Make' : 'Brand'
            }`}
            className="!mb-14 !mt-6"
            value={inputValues.make}
            error={Boolean(inputErrors.make)}
            helperText={inputErrors.make}
            onChange={onValueChange}
          />
          <TextField
            id="model"
            type="text"
            placeholder={`${capitalizeFirstLetter(type)} Model`}
            value={inputValues.model}
            error={Boolean(inputErrors.model)}
            helperText={inputErrors.model}
            onChange={onValueChange}
            className="!mb-14"
          />
          <input type="hidden" name="type" value={inputValues.type} />
          <div className="flex justify-center w-full">
            <Link to={redirect.link}>Connect my {redirect.device} instead</Link>
          </div>

          <CardActions>
            <CardAction type="primary">
              <Button
                variant="primary"
                id={`${type}-brand-not-found-submit-button`}
                type="submit"
                disabled={!submitEnabled}
              >
                Submit
              </Button>
            </CardAction>
          </CardActions>
        </NavigationPage.SingleCardContent>
      </form>
      <AdaptiveModal
        id={`${type}-brand-not-found-confirmation-modal`}
        open={submitted}
        onClose={onFinish}
      >
        <DeviceConnectionRedirect
          mode={ConnectionStatusMode.Connected}
          buttonAction={onFinish}
          statusText={{
            connecting: 'Hold tight...we’re submitting your response',
            connected: 'Thanks for your feedback!',
            failed: 'Oh no! Something went wrong. Please go back and try again',
          }}
          statusSubText={{
            connected: `We’re working hard to support all ${type}s. We’ll let you know when we support your ${type} in the future.`,
          }}
        />
      </AdaptiveModal>
    </NavigationPage>
  )
}
